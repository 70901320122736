export const routes = [
	{
		path: '/',
		name: "Landing",
		component: () => import('@/components/layout/landing/LandingContainer.vue'),
		children: [
			{
				path: "policy",
				name: "PrivacyPolicy",
				component: () => import("@/landing/pages/PrivacyPolicy.vue")
			},
			{
				path: "user-agreement",
				name: "UserAgreement",
				component: () => import("@/landing/pages/UserAgreement.vue")
			},
			{
				path: "",
				name: "Landingpage",
				component: () => import("@/landing/pages/Landing.vue")
			},
			{
				path: "/:pathMatch(.*)*",
				name: "NotFound",
				component: () => import("@/views/NotFound.vue")
			}
		],
		meta: {
			requiresAuth: false
		}
	},
	{
		path: '/profile',
		component: () => import('@/components/layout/profile/ProfileContainer.vue'),
		children: [
			{
				path: "",
				alias: "publication/:publicationId",
				props: true,
				name: "Profile",
				component: () => import("@/views/profile/Profile.vue"),
				meta: {
					requiresAuth: true,
					sitemap: {
						ignoreRoute: true
					}
				}
			},
			{
				path: "edit",
				name: "Edit",
				component: () => import("@/views/profile/ProfileEdit.vue"),
				meta: { requiresAuth: true }
			},
			{
				path: "catalog",
				name: "Catalog",
				component: () => import("@/views/profile/Catalog.vue"),
				meta: { requiresAuth: true },
				children: [
					{
						path: 'collection/:collectionId',
						props: true,
						name: 'Collection',
						component: () => import('@/views/profile/catalog/Collection.vue'),
						meta: {
							requiresAuth: true,
							sitemap: {
								ignoreRoute: true
							}
						}
					}
				]
			},
			{
				path: 'catalog/product/:productId?',
				props: true,
				name: 'Product',
				component: () => import('@/views/profile/catalog/Product.vue'),
				meta: {
					requiresAuth: true,
					sitemap: {
						ignoreRoute: true
					}
				}
			},
			/* {
				path: 'catalog/collection/:collectionId/edit',
				props: true,
				name: 'ProductEditor',
				component: () => import('@/views/profile/catalog/ProductEditor.vue')
			}, */
			{
				path: 'promotion',
				name: 'Promotion',
				component: () => import('@/views/profile/Promotion.vue'),
				meta: { requiresAuth: true }
			},
			{
				path: 'configurator',
				name: 'Configurator',
				component: () => import('@/views/profile/Configurator.vue'),
				meta: { requiresAuth: true }
			},
			{
				path: "hashtags",
				name: "Hashtags",
				component: () => import("@/views/profile/Hashtags.vue"),
				meta: { requiresAuth: true }
			},
			{
				path: "password",
				name: "Password",
				component: () => import("@/views/profile/Password.vue"),
				meta: { requiresAuth: true }
			},
			{
				path: "notifications",
				name: "Notifications",
				component: () => import("@/views/profile/notifications/Notifications.vue"),
				meta: { profileNotificationsSidebar: true }
			},
			{
				path: "notifications/settings",
				name: "NotificationsSettings",
				component: () => import("@/views/profile/notifications/Settings.vue"),
				meta: { profileNotificationsSidebar: true }
			},
			{
				path: 'about',
				name: 'About',
				component: () => import('@/views/profile/About.vue'),
				meta: { requiresAuth: true }
			},
			{
				path: "comments/:contentId",
				props: true,
				name: "Comments",
				component: () => import("@/views/profile/comments/Comments.vue"),
				meta: {
					requiresAuth: true,
					sitemap: {
						ignoreRoute: true
					}
				}
			},
			{
				path: 'chats/:dialogId?',
				props: true,
				name: "Chats",
				component: () => import('@/views/chats/Chats.vue'),
				meta: {
					requiresAuth: true,
					sitemap: {
						ignoreRoute: true
					}
				}
			},
			{
				path: 'chatsWithOrder/:dialogId?',
				props: true,
				name: 'chatsWithOrder',
				component: () => import('@/views/chats/Chats.vue'),
				meta: {
					requiresAuth: true,
					sitemap: {
						ignoreRoute: true
					}
				}
			},
			{
				path: 'settings',
				name: 'Settings',
				props: true,
				component: () => import('@/views/profile/settings/Privacy.vue'),
				meta: { profileSettingsSidebar: true }
			},
			{
				path: 'settings/promotion-managment',
				props: true,
				name: 'SettingsPromotionManagment',
				component: () => import('@/views/profile/settings/PromotionManagment.vue'),
				meta: { profileSettingsSidebar: true }
			},
			{
				path: 'settings/statistics',
				props: true,
				name: 'SettingsStatistics',
				component: () => import('@/views/profile/settings/Statistics.vue'),
				meta: { profileSettingsSidebar: true }
			}
		],
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/login",
		name: "Login",
		component: () => import("@/views/Login.vue"),
		meta: {
			requiresAuth: false
		}
	},
	{
		path: "/restore-password",
		name: "RestorePassword",
		component: () => import("@/views/RestorePassword.vue"),
		meta: { requiresAuth: false }
	},
	{
		path: "/:pathMatch(.*)*",
		name: "NotFound",
		component: () => import("@/views/NotFound.vue")
	}
]